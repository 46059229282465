@import '../../styles/vars.scss';

.icon {
  margin-top: 80px;
  margin-bottom: 100px;
}

.continue {
  width: 270px;
  margin-bottom: 100px;
}

.spinner {
  margin-top: 200px;
  margin-bottom: 100px;
}
