.pageHeading {
  max-width: 600px;
  padding: 100px 15px 0;
}
@media (min-width: 850px) {
  .pageHeading {
    padding-top: 170px;
  }
}

.prepend {
  color: #222222;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 17px;
  text-transform: uppercase;
}

.title {
  color: #222222;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 37px;
  text-align: center;
  margin-bottom: 10px;
}
@media (min-width: 850px) {
  .title {
    font-size: 41px;
    line-height: 47px;
  }
}

.description {
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 25px;
  text-align: center;
  font-weight: 400;
}