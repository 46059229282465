@import '../styles/vars.scss';

div.bottomNavSection {
  min-width: 145px;
  margin-bottom: 25px;

  @media (min-width: $md-breakpoint) {
    min-width: 175px;
  }

  .name {
    font-weight: 600;
    font-size: 12px;
  }

  .links a {
    color: $gray;
    font-size: 12px;
  }
}
