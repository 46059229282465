.contactUs .hero {
  height: 500px;
  background-color: #f8f8f8;
  background-repeat: no-repeat;
  background-size: 280px;
  background-position: calc(100% - 20px) bottom;
}
.contactUs .hero .tagLine {
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.contactUs .hero h1 {
  font-size: 48px;
  letter-spacing: 1.17px;
  font-weight: 300;
}
.contactUs .contacts {
  padding: 60px 10%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-items: center;
  justify-content: space-around;
  row-gap: 60px;
}
@media (min-width: 850px) {
  .contactUs .contacts {
    row-gap: 160px;
    padding: 160px 10%;
  }
}
.contactUs .contacts .contactBlock {
  max-width: 230px;
  text-align: center;
}
.contactUs .contacts .contactBlock h1 {
  font-weight: 300;
  font-size: 28px;
}
.contactUs .contacts .contactBlock .mail {
  font-size: 14px;
  letter-spacing: 0.7px;
}
.contactUs .contacts .contactBlock .mail:hover {
  color: #25c5ae;
}

@media (min-width: 850px) {
  .contactUs .hero {
    background-size: 400px;
    background-position: calc(50% + 280px) bottom;
  }
  .contactUs .contacts {
    grid-template-columns: repeat(3, 1fr);
  }
}