.section {
  border-top: 1px solid #e6e6e6;
  padding: 100px 32px 75px 32px;
}
@media (min-width: 850px) {
  .section {
    padding: 150px 0 125px 0;
  }
}

.sectionHeading {
  color: #222222;
  font-size: 36px;
  letter-spacing: 0.25px;
  line-height: 41px;
  text-align: center;
  font-weight: 400;
  margin: 0 0 30px;
}

.sectionDescription {
  max-width: 550px;
  color: #222222;
  font-size: 14px;
  letter-spacing: 0.7px;
  line-height: 23px;
  text-align: center;
  margin-top: 0;
}

.header {
  background: #f7f7f7;
  padding-bottom: 25px;
}

.button {
  margin-top: 30px;
  width: 270px;
  margin-bottom: 80px;
}

.tagLine {
  margin-top: 25px;
}

.stickyWrapper {
  height: 60px;
}

.sticky {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 16px;
  background: #fff;
  position: absolute;
  width: 100%;
}
@media (min-width: 850px) {
  .sticky {
    padding: 10px 64px;
  }
}
.stickyActive {
  z-index: 5;
  position: fixed;
  top: 0;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
}

.stickyLeft {
  color: #222222;
  font-size: 15px;
  display: none;
}
@media (min-width: 850px) {
  .stickyLeft {
    display: flex;
  }
}

.statsLine {
  padding: 75px 0;
}
@media (min-width: 850px) {
  .statsLine {
    padding: 125px 0;
  }
}

.faq {
  max-width: 750px;
  margin-top: 55px;
}

.issuesWrapper {
  margin-top: 75px;
}