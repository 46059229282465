.header.light .burger svg > *,
.header.light .logo svg > * {
  fill: white;
  stroke: white;
}
.header.light .link {
  color: white;
}
.header.light .link:visited, .header.light .link:hover, .header.light .link:active {
  color: white;
}
.header.light.scrolled .burger svg > *,
.header.light.scrolled .logo svg > * {
  fill: black;
  stroke: black;
}
.header.light.scrolled .link {
  color: black;
}
.header.light.scrolled .link:visited, .header.light.scrolled .link:hover, .header.light.scrolled .link:active {
  color: black;
}

.header.dark .burger svg > *,
.header.dark .logo svg > * {
  fill: black;
  stroke: black;
}
.header.dark .link {
  color: black;
}
.header.dark .link:visited, .header.dark .link:hover, .header.dark .link:active {
  color: black;
}
.header.dark.scrolled .burger svg > *,
.header.dark.scrolled .logo svg > * {
  fill: black;
  stroke: black;
}
.header.dark.scrolled .link {
  color: black;
}
.header.dark.scrolled .link:visited, .header.dark.scrolled .link:hover, .header.dark.scrolled .link:active {
  color: black;
}

.header.mixed .burger svg > *,
.header.mixed .logo svg > * {
  fill: white;
  stroke: white;
}
.header.mixed .link {
  color: white;
}
.header.mixed .link:visited, .header.mixed .link:hover, .header.mixed .link:active {
  color: white;
}
.header.mixed.scrolled .burger svg > *,
.header.mixed.scrolled .logo svg > * {
  fill: black;
  stroke: black;
}
.header.mixed.scrolled .link {
  color: black;
}
.header.mixed.scrolled .link:visited, .header.mixed.scrolled .link:hover, .header.mixed.scrolled .link:active {
  color: black;
}

.header {
  padding: 16px 16px;
  width: 100vw;
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  transition: all 0.2s ease;
  backdrop-filter: blur(0px);
}
.header .burger {
  width: 18px;
  cursor: pointer;
}
.header .logo {
  width: 130px;
}
@media (min-width: 850px) {
  .header .logo {
    width: 150px;
  }
}
.header .logo,
.header .burger {
  padding-top: 5px;
}
.header .link {
  transition: all 0.2s ease;
  font-size: 11px;
  font-weight: 600;
  cursor: pointer;
}
.header.scrollable {
  position: fixed;
}
.header.scrolled {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  border-bottom: 0.5px solid #e6e6e6;
}

@media (min-width: 850px) {
  .header {
    padding: 18px 64px;
  }
  .header .logo svg > *,
.header .burger svg > * {
    fill: black;
    stroke: black;
  }

  .header.mixed .burger svg > *,
.header.mixed .logo svg > * {
    fill: black;
    stroke: black;
  }
  .header.mixed .link {
    color: white;
  }
  .header.mixed .link:visited, .header.mixed .link:hover, .header.mixed .link:active {
    color: white;
  }
  .header.mixed.scrolled .burger svg > *,
.header.mixed.scrolled .logo svg > * {
    fill: black;
    stroke: black;
  }
  .header.mixed.scrolled .link {
    color: black;
  }
  .header.mixed.scrolled .link:visited, .header.mixed.scrolled .link:hover, .header.mixed.scrolled .link:active {
    color: black;
  }
}