@import '../styles/vars.scss';

.accordion {
  width: 100%;

  & > * {
    border-top: 1px solid $light-gray;

    &:last-child {
      border-bottom: 1px solid $light-gray;
    }
  }
}
