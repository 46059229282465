@import '../../styles/vars.scss';

.tagLine {
  ul {
    list-style: none;
    padding: 0;
    font-size: 9px;
    font-weight: bold;
    text-transform: uppercase;
    color: $dark-gray;
    letter-spacing: 0.6px;

    li:not(:first-child):before {
      content: '•';
      position: relative;
      left: -$unit;
    }
  }
}
