@import '../styles/vars';

.label {
  width: 150px;
  color: $dark-gray;
  font-size: 13px;
  letter-spacing: 0.3px;
  line-height: 41px;
  font-weight: 300;
  text-transform: uppercase;
}

.content {
  flex-grow: 1;

  > * {
    flex-grow: 1;
  }
}
