@import '../styles/vars.scss';

.profileAvatar {
  background-color: $secondary-background;
  width: 3.5em;
  height: 3.5em;
  border-radius: 3.5em;
  position: relative;

  &.clickable {
    cursor: pointer;
  }

  &.small {
    font-size: 11px;

    .star {
      width: 12px;
      height: 12px;

      .starIcon {
        margin-top: -3px;
        margin-left: -1px;
        div > svg {
          width: 6px;
          height: 6px;
        }
      }
    }
  }

  &.medium {
    font-size: 16px;

    .star {
      width: 16px;
      height: 16px;

      .starIcon {
        margin-top: -2px;
        div > svg {
          width: 10px;
          height: 10px;
        }
      }
    }
  }

  &.large {
    font-size: 28px;

    .star {
      width: 32px;
      height: 32px;

      .starIcon {
        margin-top: -2px;
      }
    }
  }

  .star {
    background-color: $main-green;
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 100%;
  }

  .initials {
    font-weight: 300;
    color: $font-middle;
  }
}
