@import '../styles/vars.scss';

.dropdownMenu {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  border-radius: 1px;
  background-color: white;
  width: 240px;
  transform: translateY(100%);

  .buttons {
    border-top: 1px solid transparentize($light-gray, 0.5);
    & > * + * {
      border-top: 1px solid $light-gray;
    }
  }
}
