.smallSupportWidget h1 {
  font-size: 30px;
  font-weight: 200;
}
.smallSupportWidget .buttons {
  display: none;
}

@media (min-width: 850px) and (max-width: 1100px) {
  .smallSupportWidget {
    width: 540px;
  }
  .smallSupportWidget h1 {
    font-size: 40px;
  }
}
@media (min-width: 850px) {
  .smallSupportWidget .donateMobile {
    display: none;
  }
  .smallSupportWidget .buttons {
    display: block;
  }
  .smallSupportWidget h1 {
    font-weight: 400;
    margin-bottom: 38px;
    font-size: 21px;
    line-height: 24px;
    color: #333333;
  }
  .smallSupportWidget button {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.75px;
    padding: 10px 15px;
  }
  .smallSupportWidget button.selected {
    background-color: #666666;
    color: white;
  }
  .smallSupportWidget button.donate {
    font-size: 12px;
    letter-spacing: 0.4px;
    padding: 11px 24px;
  }
}