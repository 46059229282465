@charset "UTF-8";
.tagLine ul {
  list-style: none;
  padding: 0;
  font-size: 9px;
  font-weight: bold;
  text-transform: uppercase;
  color: #666666;
  letter-spacing: 0.6px;
}
.tagLine ul li:not(:first-child):before {
  content: "•";
  position: relative;
  left: -8px;
}