.ModalDialog {
  width: 100%;
}

.ModalBody {
  max-width: 900px;
  margin: 0 auto;
  background: #fff;
  padding: 50px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ModalCloseIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  cursor: pointer;
  height: auto;

  svg {
    width: 100%;
  }
}
