@import '../styles/vars.scss';

.resetPassword {
  margin-bottom: 150px;
}

.form {
  margin-top: 20px;
}

.submit {
  width: 270px;
  margin-top: 18px;
}

.errorMessage {
  text-align: center;
  color: $error;
  font-size: 12px;
}
