@import '../styles/vars.scss';

div.heroSignUp {
  min-height: 500px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  font-size: 13px;
  position: relative;

  h1 {
    font-size: 42px;
    font-weight: normal;
    max-width: 260px;
    margin: 32px;
    text-align: center;
  }

  .tagLine {
    font-size: 15px;
    padding: 0px 64px;
    text-align: center;
  }

  button {
    margin-top: 15px;
  }

  .afterword {
    margin-top: 0;
    margin-bottom: 25px;
  }

  .donateButton {
    width: 270px;
    display: block;
    margin-top: 75px;
  }

  .donateReason {
    font-size: 12px;
    line-height: 17px;
    max-width: 260px;
    font-weight: 300;
    text-align: center;
    color: $gray;
  }
}

@media (min-width: $md-breakpoint) {
  div.heroSignUp {
    min-height: 800px;

    h1 {
      font-size: 52px;
    }

    .afterword {
      position: absolute;
      bottom: 80px;
    }

    .tagLine {
      padding: 0;
    }
  }
}
