@media (min-width: 850px) {
  .cookiePolicy {
    padding-bottom: 150px;
  }
}

.header {
  background-color: #f7f7f7;
  padding-bottom: 100px;
}

.content {
  padding: 0 32px;
}

.section {
  max-width: 800px;
  margin-top: 70px;
}

.sectionTitle {
  color: #222222;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 1.5px;
  line-height: 38px;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 15px;
  margin-bottom: 25px;
}
@media (min-width: 850px) {
  .sectionTitle {
    font-size: 28px;
  }
}

.sectionSubtitle {
  color: #222222;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.14px;
  line-height: 38px;
}

.sectionParagraph {
  color: #222222;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: 25px;
}
@media (min-width: 850px) {
  .sectionParagraph {
    letter-spacing: 1px;
  }
}

.spacer {
  height: 150px;
}