.forgotPassword {
  margin-bottom: 150px;
}

.form {
  margin-top: 20px;
}

.submit {
  width: 270px;
  margin-top: 18px;
}

.signIn {
  font-size: 12px;
  padding-bottom: 25px;
  text-align: center;
}
.signIn span {
  cursor: pointer;
  color: #25c5ae;
  font-weight: bold;
  margin-left: 16px;
}

.errorMessage {
  text-align: center;
  color: #c4001d;
  font-size: 12px;
}