.footer {
  border-top: 1px solid #e6e6e6;
  padding: 64px 16px 40px 16px;
}
.footer .logo {
  width: 150px;
  margin-right: 170px;
}
.footer .copyright {
  margin-top: 32px;
  font-size: 10px;
  color: #a3a3a3;
}

.bottomNav {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
}

@media (min-width: 850px) {
  .footer {
    padding: 100px 70px 40px 70px;
  }
  .footer .copyright {
    margin-top: 200px;
  }
}