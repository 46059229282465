.seatsLeft {
  color: #222222;
  font-size: 12px;
  line-height: 17px;
}
.seatsLeft p {
  margin: 0;
}
.seatsLeft span {
  color: #25c5ae;
  font-weight: bold;
}

.seatsLeftInline {
  font-weight: 300;
}
.seatsLeftInline span {
  color: #222222;
  margin-right: 5px;
}

.counter {
  height: 3px;
  width: 65px;
  max-width: 100%;
  background: #eeeeee;
  border-radius: 2px;
  overflow: hidden;
}

.used {
  background: #25c5ae;
  height: 3px;
}