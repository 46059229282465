@import '../styles/vars.scss';

.footer {
  border-top: 1px solid $light-gray;
  padding: 64px 16px 40px 16px;

  .logo {
    width: 150px;
    margin-right: 170px;
  }

  .copyright {
    margin-top: 32px;
    font-size: 10px;
    color: $gray;
  }
}

.bottomNav {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
}

@media (min-width: $md-breakpoint) {
  .footer {
    padding: 100px 70px 40px 70px;

    .copyright {
      margin-top: 200px;
    }
  }
}
