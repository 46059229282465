@import '../../styles/vars.scss';

.seatsLeft {
  margin-top: 25px;
}

.skip {
  width: 270px;
  margin-top: 50px;
}

.faqSection {
  border-top: 1px solid $light-gray;
  width: 100%;
  padding: 90px 0;
}

.faq {
  max-width: 750px;
  margin-top: 55px;
  padding: 0 32px;
}

.sectionHeading {
  color: $font-dark;
  font-size: 36px;
  letter-spacing: 0.25px;
  line-height: 41px;
  text-align: center;
  font-weight: 400;
  margin: 0 0 30px;
}

.paymentDetails {
  width: 270px;
  padding-top: 25px;
}

.formSection {
  padding-top: 35px;
}

.donateForm {
  margin-top: 50px;
}
