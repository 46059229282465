.dropdownButton {
  width: 100%;
  padding: 11px 20px;
  font-size: 13px;
  font-weight: normal;
  line-height: 33px;
  letter-spacing: 0.03px;
  color: #666666;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.dropdownButton:hover {
  background-color: #e6e6e6;
}