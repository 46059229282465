@import '../../styles/vars.scss';

.addMore {
  color: $dark-gray;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 15px;
  font-weight: 300;
  text-align: right;
  cursor: pointer;

  &:hover {
    color: #000;
  }
}
