.step {
  color: #666666;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  margin: 35px auto 50px 64px;
}

.notice {
  color: #999999;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  font-weight: 300;
  margin-top: 15px;
}

@media (min-width: 850px) {
  .step {
    margin-top: 100px;
  }
}