.donateSuccess {
  padding-bottom: 50px;
}
@media (min-width: 850px) {
  .donateSuccess {
    padding-bottom: 250px;
  }
}

.button {
  margin-top: 30px;
  width: 270px;
  margin-bottom: 80px;
}