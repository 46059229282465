@import '../../../styles/vars.scss';

.stage {
  & > h3 {
    font-size: 26px;
    color: $dark-gray;
    line-height: 28px;
    font-weight: normal;
    margin: 32px 0 12px 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-width: 300px;
  }
}

@media (min-width: $md-breakpoint) {
  .stage {
    & > h3 {
      font-size: 21px;
      width: 140px;
      text-align: right;
      margin: 18px 50px 0 0;
    }
  }
}
