@import '../styles/vars.scss';

.seatsLeft {
  color: $font-dark;
  font-size: 12px;
  line-height: 17px;

  p {
    margin: 0;
  }

  span {
    color: $main-green;
    font-weight: bold;
  }
}

.seatsLeftInline {
  font-weight: 300;

  span {
    color: $font-dark;
    margin-right: 5px;
  }
}

.counter {
  height: 3px;
  width: 65px;
  max-width: 100%;
  background: #eeeeee;
  border-radius: 2px;
  overflow: hidden;
}

.used {
  background: $main-green;
  height: 3px;
}
