.profileDropdown {
  position: relative;
}
.profileDropdown .profileInfo {
  cursor: pointer;
  padding: 20px;
}
.profileDropdown .profileInfo h3 {
  font-size: 14px;
  margin: 0;
  font-weight: 600;
  line-height: 19px;
}
.profileDropdown .profileInfo .type {
  font-size: 12px;
  line-height: 17px;
}
.profileDropdown .dropdown {
  position: absolute;
  bottom: -18px;
  right: 0;
}