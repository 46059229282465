@import '../../styles/vars.scss';

.countrySelect {
  position: relative;
}

.flag {
  width: 20px;
  top: 13px;
  position: absolute;
  left: 20px;
  z-index: 1;
  border-radius: 3px;
}

.close {
  width: 21px;
  top: 8px;
  position: absolute;
  right: 14px;
  z-index: 1;
  cursor: pointer;
  background: #fff;
  padding-left: 5px;
}

.input {
  width: 100%;
}

.countrySelected {
  padding-left: 50px;
}

.container {
  position: relative;
}

.containerOpen .input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.suggestionsContainer {
  display: none;
}

.containerOpen .suggestionsContainer {
  width: 100%;
  display: block;
  position: absolute;
  top: 100%;
  margin-top: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  background-color: #fff;
  z-index: 2;
  overflow: hidden;
}

.suggestionsList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.suggestion {
  cursor: pointer;
  padding: 0px 13px;
  color: #222222;
  font-family: $font;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 50px;

  &:hover,
  .suggestionHighlighted {
    background: #f7f7f7;
  }

  img {
    width: 20px;
    border-radius: 3px;
    overflow: hidden;
    margin-right: 10px;
    border: 1px solid #f1f1f1;
  }
}

.suggestion--focused {
  background-color: #0c7eaf;
  color: #fff;
}

.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
}

.name {
  margin-left: 68px;
  line-height: 45px;
}

.highlight {
  font-weight: bold;
}

.sectionTitle {
  color: $darker-gray;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 15s;
  padding: 5px 13px;
}

.suggestionHighlighted {
  background: #f7f7f7;
}
