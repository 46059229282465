@charset "UTF-8";
.leadershipProfile {
  margin: 0;
  margin-top: 75px;
}
.leadershipProfile img {
  width: 120px;
  height: 120px;
}
.leadershipProfile aside {
  padding-top: 16px;
}
.leadershipProfile aside .name {
  font-size: 12px;
  letter-spacing: 0.08px;
  font-weight: 800;
  text-transform: uppercase;
}
.leadershipProfile aside .position {
  color: #424242;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.08px;
  text-transform: uppercase;
}
.leadershipProfile aside ul {
  padding-left: 8px;
  list-style-type: none;
}
.leadershipProfile aside ul li {
  position: relative;
  font-size: 11px;
  letter-spacing: 0.08px;
  width: 190px;
}
.leadershipProfile aside ul li:before {
  content: "•";
  position: absolute;
  top: 0;
  left: -8px;
}

@media (min-width: 850px) {
  .leadershipProfile {
    margin: 75px 100px 0 100px;
  }
}