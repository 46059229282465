.spinner {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border-top: 1px solid #a3a3a3;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  animation: rotate infinite linear 1s;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}