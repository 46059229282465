.sideMenu {
  width: 100vw;
  background: white;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 110;
  padding: 48px 0 48px 48px;
}
.sideMenu .logo {
  width: 130px;
}
.sideMenu .seatsLeft {
  width: 40px;
}
.sideMenu nav ul {
  list-style-type: none;
  padding: 0;
  font-size: 12px;
}
.sideMenu nav ul.big {
  font-size: 28px;
  font-weight: 300;
  margin-top: 0;
}
.sideMenu nav ul.big a {
  color: black;
  display: flex;
  padding: 4px 30px 4px 0;
  transition: background 0.25s ease;
}
.sideMenu nav ul.big a:hover {
  background: #f7f7f7;
}
.sideMenu nav ul.big a:visited, .sideMenu nav ul.big a:hover, .sideMenu nav ul.big a:active {
  color: black;
}
.sideMenu nav ul a {
  color: #a3a3a3;
}
.sideMenu nav ul a:hover {
  color: #e6e6e6;
}
.sideMenu .message {
  max-width: 240px;
  color: #a3a3a3;
  font-size: 12px;
  font-weight: 300;
}
.sideMenu .message a {
  color: #25c5ae;
}
.sideMenu .message strong {
  display: block;
  margin-top: 10px;
  color: black;
  font-weight: 300;
}
.sideMenu .close {
  position: absolute;
  right: 20px;
  top: -42px;
  width: 40px;
  transform: translate(-100%, 100%);
  padding: 12px;
}
.sideMenu .close svg {
  width: 16px;
}

@media (min-width: 850px) {
  .sideMenu {
    width: 400px;
  }

  .close {
    display: none;
  }
}
.backdrop {
  background-color: black;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 105;
}