.donateForm {
  margin-top: 50px;
  max-width: 700px;
  border: 1px solid #e6e6e6;
  border-radius: 1px;
  background-color: #ffffff;
}
@media (min-width: 850px) {
  .donateForm {
    margin-top: 100px;
  }
}
.donateForm input,
.donateForm textarea {
  border-radius: 1px;
  font-size: 13px;
}
.donateForm textarea {
  height: 100px;
}

.formSection {
  padding: 25px 32px;
}
.formSection + .formSection {
  border-top: 1px solid #e6e6e6;
}
@media (min-width: 850px) {
  .formSection {
    padding: 50px 35px;
  }
}

.paySectionContent {
  text-align: center;
  max-width: 270px;
}

.formSectionTitle {
  color: #222222;
  font-size: 18px;
  letter-spacing: 0.9px;
  font-family: "Open Sans", sans-serif;
  line-height: 24px;
  margin-top: 0;
  font-weight: 400;
}

.formSectionTitleSimple {
  margin-top: 0;
  color: #666666;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  letter-spacing: 0.75px;
  line-height: 20px;
  text-align: center;
  font-weight: 300;
}

.customInput {
  padding: 0.8em 1.45em;
  cursor: text;
  color: #424242;
  border: 1px solid #e6e6e6;
  font-size: 13px;
  border-radius: 1px;
}
.customInput.invalid {
  border-color: #c4001d;
}
.customInput.active {
  border-color: #25c5ae;
}

.recaptcha {
  padding-top: 30px;
}

.footerAmount {
  font-weight: 400;
  color: #222222;
  font-size: 18px;
  letter-spacing: 0.9px;
  line-height: 24px;
}

.footerTerms {
  color: #666666;
  font-size: 12px;
  margin-top: 20px;
  letter-spacing: 0.6px;
  line-height: 17px;
}

.suffix {
  color: #666666;
  font-size: 12px;
  letter-spacing: 0.28px;
  line-height: 17px;
  font-weight: 300;
}

.characterCount {
  color: #999999;
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  letter-spacing: 0.55px;
  line-height: 15px;
  text-align: right;
  margin-top: 5px;
}