div.bottomNavSection {
  min-width: 145px;
  margin-bottom: 25px;
}
@media (min-width: 850px) {
  div.bottomNavSection {
    min-width: 175px;
  }
}
div.bottomNavSection .name {
  font-weight: 600;
  font-size: 12px;
}
div.bottomNavSection .links a {
  color: #a3a3a3;
  font-size: 12px;
}