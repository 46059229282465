@import '../styles/vars.scss';

@mixin color-header($name, $left-color, $right-color) {
  .header.#{$name} {
    .burger,
    .logo {
      svg > * {
        fill: $left-color;
        stroke: $left-color;
      }
    }

    .link {
      color: $right-color;

      &:visited,
      &:hover,
      &:active {
        color: $right-color;
      }
    }

    &.scrolled {
      .burger,
      .logo {
        svg > * {
          fill: black;
          stroke: black;
        }
      }

      .link {
        color: black;

        &:visited,
        &:hover,
        &:active {
          color: black;
        }
      }
    }
  }
}

// Header light version
@include color-header('light', white, white);

// Header dark version
@include color-header('dark', black, black);

// Header mobile mixed version (white logo, white nav)
@include color-header('mixed', white, white);

.header {
  padding: 16px 16px;
  width: 100vw;
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  transition: all 0.2s ease;
  backdrop-filter: blur(0px);

  .burger {
    width: 18px;
    cursor: pointer;
  }

  .logo {
    width: 130px;

    @media (min-width: $md-breakpoint) {
      width: 150px;
    }
  }

  .logo,
  .burger {
    padding-top: 5px;
  }

  .link {
    transition: all 0.2s ease;
    font-size: 11px;
    font-weight: 600;
    cursor: pointer;
  }

  &.scrollable {
    position: fixed;
  }

  &.scrolled {
    background: rgba(white, 0.8);
    backdrop-filter: blur(10px);
    border-bottom: 0.5px solid $light-gray;
  }
}

@media (min-width: $md-breakpoint) {
  .header {
    padding: 18px 64px;

    .logo,
    .burger {
      svg > * {
        fill: black;
        stroke: black;
      }
    }
  }

  @include color-header('mixed', black, white);
}
