input,
textarea {
  background: linear-gradient((#fff, #fff));
  padding: 0.8em 1.5em;
  font-family: $font;
  letter-spacing: 0.65px;
  outline: none;
  color: $main-font;
  border: 1px solid $light-gray;
  border-radius: 4px;
  font-size: 13px;

  &.invalid {
    border-color: $error;
  }

  &:focus {
    border-color: $main-green;
  }

  &.noBorder {
    border-radius: 0;
  }

  &::placeholder {
    color: $gray;
  }
}

button,
.button {
  padding: 0.9em 2.4em;
  background: $main-gradient;
  color: white;
  text-transform: uppercase;
  border: none;
  border-radius: 50vh;
  cursor: pointer;
  outline: none;
  font-size: 13px;
  transition: all 0.5s ease;
  letter-spacing: 0.9px;
  text-align: center;

  &:disabled {
    opacity: 0.5;
    cursor: default;

    &:hover {
      background: $main-gradient;
    }
  }

  &:visited {
    color: #fff;
  }

  &:hover {
    background: linear-gradient($main-green, $main-green);
  }

  &:active {
    background: linear-gradient($secondary-green, $secondary-green);
  }

  &.medium {
    font-size: 12px;
    padding: 0.55em 2.4em;
    letter-spacing: 0.4px;
  }

  &.white {
    background: white;
    border: 1px solid white;
    color: $main-font;

    &:hover {
      background: white;
    }

    &:active {
      background: white;
    }

    &Border {
      border: 1px solid $light-gray;

      &:hover,
      &:active {
        border-color: $main-green;
      }
    }
  }

  &.ghost {
    background: transparent;
    color: $secondary-green;
    border: 1px solid $secondary-green;

    &:hover {
      color: white;
      background: $main-green;
    }

    &:active {
      color: white;
      background: $secondary-green;
    }
  }

  &.ghost-gray {
    background: transparent;
    color: $dark-gray;
    border: 1px solid $dark-gray;
    text-transform: none;

    &:hover {
      color: white;
      background: $dark-gray;
    }

    &:active {
      color: white;
      background: $dark-gray;
    }
  }

  &.xs-narrow {
    padding-left: 1.2em;
    padding-right: 1.2em;
  }

  &.wide {
    padding-left: 3.5em;
    padding-right: 3.5em;
  }
}
