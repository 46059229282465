@import '../styles/vars.scss';

.celebrationTag {
  font-size: 11px;
  font-weight: 300;
  position: relative;

  .country {
    position: absolute;
    top: 20px;
    font-size: 12px;
  }
}
