.preferenceGroup h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: #999999;
  padding-bottom: 10px;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  border-bottom: 1px solid #e6e6e6;
}

.preference {
  cursor: pointer;
}

.preferenceCheckbox {
  display: none;
}

.preferenceCheckmark {
  display: block;
  width: 12px;
  height: 12px;
  border: 1px solid #a3a3a3;
  line-height: 7px;
  top: 5px;
  position: relative;
}
.preferenceCheckmark svg {
  height: 12px;
  width: auto;
}
.preferenceCheckmark path {
  stroke: #25c5ae;
}

.preferenceTitle {
  color: #333333;
  font-size: 18px;
  line-height: 24px;
  display: block;
  margin-top: 0;
  margin-bottom: 4px;
  font-weight: 400;
}

.preferenceSubTitle {
  color: #999999;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 17px;
  display: block;
  font-weight: 300;
}

.submit {
  margin-top: 100px;
}

.skip {
  margin-top: 30px;
  color: #999999;
  font-size: 11px;
  font-weight: 600;
  line-height: 15px;
  text-transform: uppercase;
  margin-bottom: 25px;
}

@media (min-width: 850px) {
  .skip {
    margin-bottom: 100px;
  }
}