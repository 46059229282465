@import '../styles/vars.scss';

.dropdownButton {
  width: 100%;
  padding: 11px 20px;
  font-size: 13px;
  font-weight: normal;
  line-height: 33px;
  letter-spacing: 0.03px;
  color: $dark-gray;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: $light-gray;
  }
}
