.form {
  width: 270px;
}
.form input:last-of-type {
  margin-bottom: 7px;
}

.terms {
  font-size: 12px;
  line-height: 17px;
  max-width: 260px;
  font-weight: 300;
  text-align: center;
  color: #a3a3a3;
}
.terms a {
  color: #a3a3a3;
  font-weight: 400;
}
.terms a:hover {
  color: #111;
}
.terms strong {
  white-space: nowrap;
}

.signIn {
  font-size: 12px;
  padding-bottom: 25px;
}
.signIn span {
  cursor: pointer;
  color: #25c5ae;
  font-weight: bold;
  margin-left: 16px;
}

.errorMessage {
  text-align: center;
  color: #c4001d;
  font-size: 12px;
}