@import '../styles/vars';

.donateSuccess {
  padding-bottom: 50px;
  @media (min-width: $md-breakpoint) {
    padding-bottom: 250px;
  }
}

.button {
  margin-top: 30px;
  width: 270px;
  margin-bottom: 80px;
}
