@import '../styles/vars.scss';

.termsOfService {
  @media (min-width: $md-breakpoint) {
    padding-bottom: 150px;
  }
}

.header {
  background-color: #f7f7f7;
  padding-bottom: 100px;
}

.content {
  padding: 0 32px;
}

.section {
  max-width: 800px;
  margin-top: 70px;
}

.sectionTitle {
  color: $font-dark;
  font-family: $font;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 1.5px;
  line-height: 38px;
  border-bottom: 1px solid $light-gray;
  padding-bottom: 15px;
  margin-bottom: 25px;

  @media (min-width: $md-breakpoint) {
    font-size: 28px;
  }
}

.sectionSubtitle {
  color: $font-dark;
  font-family: $font;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.14px;
  line-height: 38px;
}

.sectionParagraph {
  color: $font-dark;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: 25px;

  @media (min-width: $md-breakpoint) {
    letter-spacing: 1px;
  }
}
