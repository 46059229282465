.heading {
  font-size: 36px;
  text-align: center;
  color: #000;
  font-weight: 300;
  margin-bottom: 15px;
}

.tagLine {
  font-size: 14px;
  margin-bottom: 35px;
  font-weight: 300;
}

.continue {
  margin-top: 15px;
  font-size: 12px;
}

.errorMessage {
  text-align: center;
  color: #c4001d;
  font-size: 12px;
}

.termsLine {
  text-align: center;
  color: #999999;
  font-size: 12px;
}
.termsLine a {
  color: #999999;
  font-weight: 600;
}

.registerLine {
  text-align: center;
  font-size: 12px;
}
.registerLine a {
  color: #25c5ae;
  display: inline-block;
  margin-left: 15px;
  font-weight: 600;
}

.forgotPassword {
  color: #999999;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
}