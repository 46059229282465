.stage > h3 {
  font-size: 26px;
  color: #666666;
  line-height: 28px;
  font-weight: normal;
  margin: 32px 0 12px 0;
}
.stage ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-width: 300px;
}

@media (min-width: 850px) {
  .stage > h3 {
    font-size: 21px;
    width: 140px;
    text-align: right;
    margin: 18px 50px 0 0;
  }
}