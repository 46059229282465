@import '../styles/vars.scss';

.accordionItem {
  .title {
    position: relative;
    padding: 18px;
    padding-right: 48px;
    cursor: pointer;
    font-size: 15px;

    &:after {
      content: '+';
      position: absolute;

      right: 28px;
      top: 50%;
      transform: translateY(-50%);
      color: $gray;
    }
  }

  .content {
    font-size: 13px;
    max-height: 0;
    overflow: hidden;
    padding: 0 18px;
    transition: all 0.5s ease;

    &Active {
      max-height: 40em;
    }

    p {
      font-size: 13px;
      letter-spacing: 0.3px;
      line-height: 21px;
      &:last-child {
        margin-bottom: 25px;
      }
    }
  }
}
