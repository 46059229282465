@import '../styles/vars.scss';

.leadershipProfile {
  margin: 0;
  margin-top: 75px;

  img {
    width: 120px;
    height: 120px;
  }

  aside {
    padding-top: 16px;

    .name {
      font-size: 12px;
      letter-spacing: 0.08px;
      font-weight: 800;
      text-transform: uppercase;
    }

    .position {
      color: $main-font;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 0.08px;
      text-transform: uppercase;
    }

    ul {
      padding-left: 8px;
      list-style-type: none;

      li {
        position: relative;
        font-size: 11px;
        letter-spacing: 0.08px;
        width: 190px;

        &:before {
          content: '•';
          position: absolute;
          top: 0;
          left: -8px;
        }
      }
    }
  }
}

@media (min-width: $md-breakpoint) {
  .leadershipProfile {
    margin: 75px 100px 0 100px;
  }
}
