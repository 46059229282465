@charset "UTF-8";
@media (min-width: 0px) {
  [data-flex] {
    flex: 1 0 0%;
  }

  [data-flex="2"] {
    flex: 2 0 0%;
  }

  [data-flex="3"] {
    flex: 3 0 0%;
  }

  [data-flex="4"] {
    flex: 4 0 0%;
  }

  [data-flex="5"] {
    flex: 5 0 0%;
  }

  [data-flex="6"] {
    flex: 6 0 0%;
  }

  [data-layout] {
    display: flex;
    align-items: stretch;
    min-width: 0;
    min-height: 0;
  }
  [data-layout] > * {
    flex-shrink: 0;
  }

  [data-layout~=inline] {
    display: inline-flex;
  }

  [data-layout~=column] {
    flex-direction: column;
  }

  [data-layout~=row] {
    flex-direction: row;
  }

  [data-layout~=row-reverse] {
    flex-direction: row-reverse;
  }

  [data-layout~=align-center] {
    align-items: center;
  }

  [data-layout~=align-baseline] {
    align-items: baseline;
  }

  [data-layout~=align-start] {
    align-items: flex-start;
  }

  [data-layout~=align-end] {
    align-items: flex-end;
  }

  [data-layout~=end] {
    justify-content: flex-end;
  }

  [data-layout~=between] {
    justify-content: space-between;
  }

  [data-layout~=around] {
    justify-content: space-around;
  }

  [data-layout~=middle] {
    justify-content: center;
  }

  [data-layout~=start] {
    justify-content: flex-start;
  }

  [data-layout~=wrap] {
    flex-wrap: wrap;
  }

  [data-layout~=u0][data-layout~=row] > * + * {
    margin-top: 0;
    margin-left: 0;
  }
  [data-layout~=u0][data-layout~=column] > * + * {
    margin-top: 0;
    margin-left: 0;
  }

  [data-layout~=u05][data-layout~=row] > * + * {
    margin-top: 0;
    margin-left: 4px;
  }
  [data-layout~=u05][data-layout~=column] > * + * {
    margin-top: 4px;
    margin-left: 0;
  }

  [data-layout~=u1][data-layout~=row] > * + * {
    margin-top: 0;
    margin-left: 8px;
  }
  [data-layout~=u1][data-layout~=column] > * + * {
    margin-top: 8px;
    margin-left: 0;
  }

  [data-layout~=u2][data-layout~=row] > * + * {
    margin-top: 0;
    margin-left: 16px;
  }
  [data-layout~=u2][data-layout~=column] > * + * {
    margin-top: 16px;
    margin-left: 0;
  }

  [data-layout~=u3][data-layout~=row] > * + * {
    margin-top: 0;
    margin-left: 24px;
  }
  [data-layout~=u3][data-layout~=column] > * + * {
    margin-top: 24px;
    margin-left: 0;
  }

  [data-layout~=u4][data-layout~=row] > * + * {
    margin-top: 0;
    margin-left: 32px;
  }
  [data-layout~=u4][data-layout~=column] > * + * {
    margin-top: 32px;
    margin-left: 0;
  }

  [data-layout~=u5][data-layout~=row] > * + * {
    margin-top: 0;
    margin-left: 40px;
  }
  [data-layout~=u5][data-layout~=column] > * + * {
    margin-top: 40px;
    margin-left: 0;
  }
}
@media (min-width: 850px) {
  [data-flex-md] {
    flex: 1 0 0%;
  }

  [data-flex-md="2"] {
    flex: 2 0 0%;
  }

  [data-flex-md="3"] {
    flex: 3 0 0%;
  }

  [data-flex-md="4"] {
    flex: 4 0 0%;
  }

  [data-flex-md="5"] {
    flex: 5 0 0%;
  }

  [data-flex-md="6"] {
    flex: 6 0 0%;
  }

  [data-layout-md] {
    display: flex;
    align-items: stretch;
    min-width: 0;
    min-height: 0;
  }
  [data-layout-md] > * {
    flex-shrink: 0;
  }

  [data-layout-md~=inline] {
    display: inline-flex;
  }

  [data-layout-md~=column] {
    flex-direction: column;
  }

  [data-layout-md~=row] {
    flex-direction: row;
  }

  [data-layout-md~=row-reverse] {
    flex-direction: row-reverse;
  }

  [data-layout-md~=align-center] {
    align-items: center;
  }

  [data-layout-md~=align-baseline] {
    align-items: baseline;
  }

  [data-layout-md~=align-start] {
    align-items: flex-start;
  }

  [data-layout-md~=align-end] {
    align-items: flex-end;
  }

  [data-layout-md~=end] {
    justify-content: flex-end;
  }

  [data-layout-md~=between] {
    justify-content: space-between;
  }

  [data-layout-md~=around] {
    justify-content: space-around;
  }

  [data-layout-md~=middle] {
    justify-content: center;
  }

  [data-layout-md~=start] {
    justify-content: flex-start;
  }

  [data-layout-md~=wrap] {
    flex-wrap: wrap;
  }

  [data-layout-md~=u0][data-layout-md~=row] > * + * {
    margin-top: 0;
    margin-left: 0;
  }
  [data-layout-md~=u0][data-layout-md~=column] > * + * {
    margin-top: 0;
    margin-left: 0;
  }

  [data-layout-md~=u05][data-layout-md~=row] > * + * {
    margin-top: 0;
    margin-left: 4px;
  }
  [data-layout-md~=u05][data-layout-md~=column] > * + * {
    margin-top: 4px;
    margin-left: 0;
  }

  [data-layout-md~=u1][data-layout-md~=row] > * + * {
    margin-top: 0;
    margin-left: 8px;
  }
  [data-layout-md~=u1][data-layout-md~=column] > * + * {
    margin-top: 8px;
    margin-left: 0;
  }

  [data-layout-md~=u2][data-layout-md~=row] > * + * {
    margin-top: 0;
    margin-left: 16px;
  }
  [data-layout-md~=u2][data-layout-md~=column] > * + * {
    margin-top: 16px;
    margin-left: 0;
  }

  [data-layout-md~=u3][data-layout-md~=row] > * + * {
    margin-top: 0;
    margin-left: 24px;
  }
  [data-layout-md~=u3][data-layout-md~=column] > * + * {
    margin-top: 24px;
    margin-left: 0;
  }

  [data-layout-md~=u4][data-layout-md~=row] > * + * {
    margin-top: 0;
    margin-left: 32px;
  }
  [data-layout-md~=u4][data-layout-md~=column] > * + * {
    margin-top: 32px;
    margin-left: 0;
  }

  [data-layout-md~=u5][data-layout-md~=row] > * + * {
    margin-top: 0;
    margin-left: 40px;
  }
  [data-layout-md~=u5][data-layout-md~=column] > * + * {
    margin-top: 40px;
    margin-left: 0;
  }
}
@media (min-width: 1100px) {
  [data-flex-lg] {
    flex: 1 0 0%;
  }

  [data-flex-lg="2"] {
    flex: 2 0 0%;
  }

  [data-flex-lg="3"] {
    flex: 3 0 0%;
  }

  [data-flex-lg="4"] {
    flex: 4 0 0%;
  }

  [data-flex-lg="5"] {
    flex: 5 0 0%;
  }

  [data-flex-lg="6"] {
    flex: 6 0 0%;
  }

  [data-layout-lg] {
    display: flex;
    align-items: stretch;
    min-width: 0;
    min-height: 0;
  }
  [data-layout-lg] > * {
    flex-shrink: 0;
  }

  [data-layout-lg~=inline] {
    display: inline-flex;
  }

  [data-layout-lg~=column] {
    flex-direction: column;
  }

  [data-layout-lg~=row] {
    flex-direction: row;
  }

  [data-layout-lg~=row-reverse] {
    flex-direction: row-reverse;
  }

  [data-layout-lg~=align-center] {
    align-items: center;
  }

  [data-layout-lg~=align-baseline] {
    align-items: baseline;
  }

  [data-layout-lg~=align-start] {
    align-items: flex-start;
  }

  [data-layout-lg~=align-end] {
    align-items: flex-end;
  }

  [data-layout-lg~=end] {
    justify-content: flex-end;
  }

  [data-layout-lg~=between] {
    justify-content: space-between;
  }

  [data-layout-lg~=around] {
    justify-content: space-around;
  }

  [data-layout-lg~=middle] {
    justify-content: center;
  }

  [data-layout-lg~=start] {
    justify-content: flex-start;
  }

  [data-layout-lg~=wrap] {
    flex-wrap: wrap;
  }

  [data-layout-lg~=u0][data-layout-lg~=row] > * + * {
    margin-top: 0;
    margin-left: 0;
  }
  [data-layout-lg~=u0][data-layout-lg~=column] > * + * {
    margin-top: 0;
    margin-left: 0;
  }

  [data-layout-lg~=u05][data-layout-lg~=row] > * + * {
    margin-top: 0;
    margin-left: 4px;
  }
  [data-layout-lg~=u05][data-layout-lg~=column] > * + * {
    margin-top: 4px;
    margin-left: 0;
  }

  [data-layout-lg~=u1][data-layout-lg~=row] > * + * {
    margin-top: 0;
    margin-left: 8px;
  }
  [data-layout-lg~=u1][data-layout-lg~=column] > * + * {
    margin-top: 8px;
    margin-left: 0;
  }

  [data-layout-lg~=u2][data-layout-lg~=row] > * + * {
    margin-top: 0;
    margin-left: 16px;
  }
  [data-layout-lg~=u2][data-layout-lg~=column] > * + * {
    margin-top: 16px;
    margin-left: 0;
  }

  [data-layout-lg~=u3][data-layout-lg~=row] > * + * {
    margin-top: 0;
    margin-left: 24px;
  }
  [data-layout-lg~=u3][data-layout-lg~=column] > * + * {
    margin-top: 24px;
    margin-left: 0;
  }

  [data-layout-lg~=u4][data-layout-lg~=row] > * + * {
    margin-top: 0;
    margin-left: 32px;
  }
  [data-layout-lg~=u4][data-layout-lg~=column] > * + * {
    margin-top: 32px;
    margin-left: 0;
  }

  [data-layout-lg~=u5][data-layout-lg~=row] > * + * {
    margin-top: 0;
    margin-left: 40px;
  }
  [data-layout-lg~=u5][data-layout-lg~=column] > * + * {
    margin-top: 40px;
    margin-left: 0;
  }
}
input,
textarea {
  background: linear-gradient(#fff, #fff);
  padding: 0.8em 1.5em;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0.65px;
  outline: none;
  color: #424242;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  font-size: 13px;
}
input.invalid,
textarea.invalid {
  border-color: #c4001d;
}
input:focus,
textarea:focus {
  border-color: #25c5ae;
}
input.noBorder,
textarea.noBorder {
  border-radius: 0;
}
input::placeholder,
textarea::placeholder {
  color: #a3a3a3;
}

button,
.button {
  padding: 0.9em 2.4em;
  background: linear-gradient(to right, #25c5ae, #23adae);
  color: white;
  text-transform: uppercase;
  border: none;
  border-radius: 50vh;
  cursor: pointer;
  outline: none;
  font-size: 13px;
  transition: all 0.5s ease;
  letter-spacing: 0.9px;
  text-align: center;
}
button:disabled,
.button:disabled {
  opacity: 0.5;
  cursor: default;
}
button:disabled:hover,
.button:disabled:hover {
  background: linear-gradient(to right, #25c5ae, #23adae);
}
button:visited,
.button:visited {
  color: #fff;
}
button:hover,
.button:hover {
  background: linear-gradient(#25c5ae, #25c5ae);
}
button:active,
.button:active {
  background: linear-gradient(#23adae, #23adae);
}
button.medium,
.button.medium {
  font-size: 12px;
  padding: 0.55em 2.4em;
  letter-spacing: 0.4px;
}
button.white,
.button.white {
  background: white;
  border: 1px solid white;
  color: #424242;
}
button.white:hover,
.button.white:hover {
  background: white;
}
button.white:active,
.button.white:active {
  background: white;
}
button.whiteBorder,
.button.whiteBorder {
  border: 1px solid #e6e6e6;
}
button.whiteBorder:hover, button.whiteBorder:active,
.button.whiteBorder:hover,
.button.whiteBorder:active {
  border-color: #25c5ae;
}
button.ghost,
.button.ghost {
  background: transparent;
  color: #23adae;
  border: 1px solid #23adae;
}
button.ghost:hover,
.button.ghost:hover {
  color: white;
  background: #25c5ae;
}
button.ghost:active,
.button.ghost:active {
  color: white;
  background: #23adae;
}
button.ghost-gray,
.button.ghost-gray {
  background: transparent;
  color: #666666;
  border: 1px solid #666666;
  text-transform: none;
}
button.ghost-gray:hover,
.button.ghost-gray:hover {
  color: white;
  background: #666666;
}
button.ghost-gray:active,
.button.ghost-gray:active {
  color: white;
  background: #666666;
}
button.xs-narrow,
.button.xs-narrow {
  padding-left: 1.2em;
  padding-right: 1.2em;
}
button.wide,
.button.wide {
  padding-left: 3.5em;
  padding-right: 3.5em;
}

body {
  color: #424242;
  font-family: "Open Sans", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

h1,
h2 {
  font-family: "Georgia", "Source Serif Pro", serif;
}

* {
  box-sizing: border-box;
}

a {
  color: #424242;
  text-decoration: none;
}
a:hover {
  color: inherit;
}
a:visited {
  color: inherit;
}
a:active {
  color: inherit;
}

.svg-wrapper {
  position: relative;
}
.svg-wrapper div {
  display: inline-block;
}
.svg-wrapper div svg {
  max-width: 100%;
  max-height: 100%;
  height: auto;
}

.todo {
  border: 2px solid #f1c40f;
  border-radius: 2px;
  color: #e67e22;
  width: 300px;
  padding: 16px;
  text-align: center;
  position: relative;
}
.todo:before {
  content: "⚠️TODO: ";
  font-weight: 600;
  position: absolute;
  top: -26px;
  left: 0px;
}