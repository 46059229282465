.option {
  padding: 0.8em 1.4em;
  color: white;
  border-radius: 30px;
  cursor: pointer;
  outline: none;
  border: 1px solid #a3a3a3;
  color: #666666;
  font-size: 14px;
  letter-spacing: 0.8px;
  line-height: 22px;
  font-weight: 300;
  width: 30%;
  text-align: center;
  margin-bottom: 10px;
}
@media (min-width: 850px) {
  .option {
    padding: 1.1em 1.7em;
    font-size: 16px;
    width: auto;
  }
}
.option:disabled {
  opacity: 0.5;
}
.option:hover {
  border-color: #25c5ae;
}
.optionActive, .option:active {
  border-color: transparent;
  background: linear-gradient(to right, #25c5ae, #23adae);
  color: #fff;
}
@media (min-width: 850px) {
  .optionSmall {
    padding: 0.5em 1em;
    margin: 0 0.2em;
    font-size: 14px;
    width: auto;
  }
}

.customValue {
  background-color: #f7f7f7;
  margin-top: 25px;
  padding: 15px 25px;
  color: #666666;
  font-size: 16px;
  letter-spacing: 0.8px;
  line-height: 22px;
  font-weight: 300;
}

.customValueInput {
  font-size: 13px;
  border-radius: 0;
  flex-grow: 1;
}
@media (min-width: 850px) {
  .customValueInput {
    margin-right: 2em;
    flex-grow: 0;
  }
}

.currency {
  padding: 1em 1em;
  color: #424242;
  border: 1px solid #e6e6e6;
  background: #fff;
  color: #999999;
  font-size: 13px;
  letter-spacing: 0.65px;
  line-height: 18px;
  border-right: 0;
}
@media (min-width: 850px) {
  .currency {
    margin-left: 1em;
  }
}