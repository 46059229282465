@import '../../styles/vars.scss';

.faq {
  width: 90vw;
}

@media (min-width: $md-breakpoint) {
  .faq {
    width: 750px;
  }
}
