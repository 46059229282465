.milestone {
  margin-bottom: 30px;
  position: relative;
}
.milestone.nextDone:before {
  background-color: #25c5ae;
}
.milestone:before {
  content: "";
  position: absolute;
  top: 45px;
  left: 16px;
  width: 2px;
  height: calc(100% - 26px);
  background-color: #e6e6e6;
}
.milestone .checkIcon {
  border: 2px solid #e6e6e6;
  width: 35px;
  height: 35px;
  border-radius: 100%;
}
.milestone .checkIcon.done {
  background-color: #25c5ae;
  border: 2px solid #25c5ae;
}
.milestone aside {
  max-width: 220px;
}
.milestone aside h3 {
  margin: 5px 0;
  font-size: 16px;
  line-height: 22px;
}
.milestone aside .description,
.milestone aside .date {
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 21px;
}
.milestone aside .description {
  font-weight: 400;
}
.milestone aside .date {
  font-weight: 300;
  color: #666666;
}
.milestone .endCap {
  width: 10px;
  height: 10px;
  margin: 0;
  position: absolute;
  bottom: -24px;
  left: 12px;
  background-color: #e6e6e6;
  border-radius: 100%;
}