@media (min-width: 850px) {
  .donate {
    padding-bottom: 50px;
  }
}

.faqSection {
  width: 100%;
  padding: 70px 32px;
}
@media (min-width: 850px) {
  .faqSection {
    border-top: 1px solid #e6e6e6;
    margin-top: 100px;
    padding: 90px 0;
  }
}

.faq {
  max-width: 750px;
  margin-top: 55px;
}

.sectionHeading {
  color: #222222;
  font-size: 30px;
  letter-spacing: 0.25px;
  line-height: 41px;
  text-align: center;
  font-weight: 400;
  margin: 0 0 30px;
}
@media (min-width: 850px) {
  .sectionHeading {
    font-size: 36px;
  }
}