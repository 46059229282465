.dashboard .mainSection {
  text-align: center;
  padding: 0 32px 64px;
}
.dashboard .mainSection h1 {
  margin-top: 100px;
  font-size: 36px;
  line-height: 41px;
  font-weight: 200;
  max-width: 400px;
}
.dashboard .mainSection .stayTuned {
  font-size: 14px;
  line-height: 23px;
  max-width: 580px;
  margin-bottom: 35px;
}
.dashboard .mainSection button {
  margin-top: 5px;
  padding: 5px 10px 0px 10px;
}
.dashboard .schedule {
  padding-top: 175px;
  border-top: none;
}