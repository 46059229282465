@import '../styles/_vars.scss';

.sideMenu {
  width: 100vw;
  background: white;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 110;
  padding: 48px 0 48px 48px;

  .logo {
    width: 130px;
  }

  .seatsLeft {
    width: 40px;
  }

  nav {
    ul {
      list-style-type: none;
      padding: 0;
      font-size: 12px;

      &.big {
        font-size: 28px;
        font-weight: 300;
        margin-top: 0;

        a {
          color: black;
          display: flex;
          padding: $unit-half 30px $unit-half 0;
          transition: background 0.25s ease;

          &:hover {
            background: #f7f7f7;
          }

          &:visited,
          &:hover,
          &:active {
            color: black;
          }
        }
      }

      a {
        color: $gray;

        &:hover {
          color: $light-gray;
        }
      }
    }
  }

  .message {
    max-width: 240px;
    color: $gray;
    font-size: 12px;
    font-weight: 300;

    a {
      color: $main-green;
    }

    strong {
      display: block;
      margin-top: 10px;
      color: black;
      font-weight: 300;
    }
  }

  .close {
    position: absolute;
    right: 20px;
    top: -42px;
    width: 40px;
    transform: translate(-100%, 100%);
    padding: 12px;

    svg {
      width: 16px;
    }
  }
}

@media (min-width: $md-breakpoint) {
  .sideMenu {
    width: 400px;
  }

  .close {
    display: none;
  }
}

.backdrop {
  background-color: black;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 105;
}
