@import '../styles/vars.scss';

.smallSupportWidget {
  h1 {
    font-size: 30px;
    font-weight: 200;
  }

  .buttons {
    display: none;
  }
}

@media (min-width: $md-breakpoint) and (max-width: $lg-breakpoint) {
  .smallSupportWidget {
    width: 540px;
    h1 {
      font-size: 40px;
    }
  }
}

@media (min-width: $md-breakpoint) {
  .smallSupportWidget {
    .donateMobile {
      display: none;
    }
    .buttons {
      display: block;
    }

    h1 {
      font-weight: 400;
      margin-bottom: 38px;
      font-size: 21px;
      line-height: 24px;
      color: $font-middle;
    }

    button {
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.75px;
      padding: 10px 15px;

      &.selected {
        background-color: $dark-gray;
        color: white;
      }

      &.donate {
        font-size: 12px;
        letter-spacing: 0.4px;
        padding: 11px 24px;
      }
    }
  }
}
