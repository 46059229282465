@import '../styles/vars.scss';

.forgotPassword {
  margin-bottom: 150px;
}

.form {
  margin-top: 20px;
}

.submit {
  width: 270px;
  margin-top: 18px;
}

.signIn {
  font-size: 12px;
  padding-bottom: 25px;
  text-align: center;

  span {
    cursor: pointer;
    color: $main-green;
    font-weight: bold;
    margin-left: $unit-x2;
  }
}

.errorMessage {
  text-align: center;
  color: $error;
  font-size: 12px;
}
