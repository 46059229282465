@import '../styles/vars.scss';

.profileDropdown {
  position: relative;

  .profileInfo {
    cursor: pointer;
    padding: 20px;

    h3 {
      font-size: 14px;
      margin: 0;
      font-weight: 600;
      line-height: 19px;
    }

    .type {
      font-size: 12px;
      line-height: 17px;
    }
  }

  .dropdown {
    position: absolute;
    bottom: -18px;
    right: 0;
  }
}
