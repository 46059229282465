// COLORS
$main-green: #25c5ae;
$secondary-green: #23adae;
$error: #c4001d;

$main-font: #424242;
$font-dark: #222222;
$font-middle: #333333;
$gray: #a3a3a3;
$darker-gray: #999999;
$dark-gray: #666666;
$light-gray: #e6e6e6;
$secondary-background: #f8f8f8;
$vibrant-background: #e9f9f7;
$main-gradient: linear-gradient(to right, $main-green, $secondary-green);

// UNITS
$unit: 8px;
$unit-half: $unit * 0.5; // 4px
$unit-x2: $unit * 2; // 16px
$unit-x3: $unit * 3; // 24px
$unit-x4: $unit * 4; // 32px
$unit-x5: $unit * 5; // 40px

// BREAKPOINTS
$md-breakpoint: 850px;
$lg-breakpoint: 1100px;

// FONTS
$font: 'Open Sans', sans-serif;
$font-serif: 'Georgia', 'Source Serif Pro', serif;
