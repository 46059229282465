@import '../styles/vars.scss';

.header {
  background: #f7f7f7;
  padding-bottom: 25px;
}

.pageHeading {
  max-width: 750px;
  margin-bottom: 45px;
}

.tagLine {
  margin-top: 150px;
}

.button {
  width: 270px;
}

.mission {
  .ctaSection {
    padding: 0 32px;
    position: relative;
    height: 710px;

    h3 {
      color: $font-dark;
      font-weight: 300;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-size: 12px;
    }

    h1 {
      font-size: 38px;
      line-height: 47px;
      font-weight: 200;
      letter-spacing: 1px;
      max-width: 760px;
      text-align: center;
    }

    button {
      min-width: 270px;
    }
  }

  .missionSection {
    background-color: $vibrant-background;
    padding: 64px 32px;

    .wrapper {
      max-width: 500px;

      h1 {
        letter-spacing: 0.25px;
        line-height: 41px;
        font-size: 35px;
        font-weight: 200;
      }

      p {
        font-size: 14px;
        letter-spacing: 0.3px;
        line-height: 25px;
      }
    }
  }

  .leadershipSection {
    padding: 64px 32px;
    border-top: 1px solid $light-gray;

    h1 {
      margin-bottom: 0;
      font-size: 36px;
      letter-spacing: 0.02px;
      line-height: 41px;
      color: $font-middle;
      font-weight: normal;

      @media (min-width: $md-breakpoint) {
        margin-bottom: 30px;
      }
    }

    .leadershipProfiles {
      flex-wrap: wrap;
    }
  }

  .supportSection {
    padding: 64px 32px;
    border-top: 1px solid $light-gray;

    h1 {
      color: $font-middle;
      font-size: 36px;
      line-height: 41px;
      letter-spacing: 0.25px;
      font-weight: 200;
      margin: 0;
      text-align: center;
    }

    p {
      max-width: 560px;
      text-align: center;
      font-size: 14px;
      line-height: 25px;
      letter-spacing: 0.35px;
      color: $main-font;
    }

    button {
      width: 164px;
    }
  }
}

@media (min-width: $md-breakpoint) {
  .mission {
    & > * {
      padding-left: 0;
      padding-right: 0;
    }

    .ctaSection {
      h1 {
        font-size: 41px;
      }
    }

    .missionSection {
      padding-top: 128px;
      padding-bottom: 128px;
    }

    .leadershipSection {
      padding: 146px 128px 260px 128px;
    }

    .supportSection {
      padding: 150px 0;
    }
  }
}
