.issue {
  height: 140px;
  width: 140px;
  border: 2px solid transparent;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.05);
  margin: 5px;
  transition: 0.25s all ease;
}
@media (min-width: 850px) {
  .issue {
    height: 190px;
    width: 190px;
    margin: 10px;
    padding: 10px;
  }
}
.issue.clickable {
  cursor: pointer;
}
.issue.clickable:hover, .issue.clickable.active {
  border-color: #25c5ae;
}

.issueTitle {
  color: #222222;
  font-size: 13px;
  letter-spacing: 0.65px;
  line-height: 18px;
  text-align: center;
  min-height: 36px;
}