@import '../../styles/vars.scss';

.signUp {
  form {
    margin-top: 40px;
  }
}

.seatsLeft {
  margin-bottom: 100px;
}
