.item {
  text-align: center;
}
@media (min-width: 850px) {
  .item + .item {
    margin-left: 94px;
  }
}
@media (min-width: 850px) {
  .item {
    text-align: left;
  }
}
.item span {
  color: #222222;
  font-size: 48px;
  font-weight: 300;
  line-height: 65px;
}
.item p {
  width: 184px;
  color: #222222;
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 20px;
  white-space: pre-line;
}