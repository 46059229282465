@import '../styles/vars.scss';

div.landingSection {
  position: relative;
  background-color: white;
  border-top: 1px solid $light-gray;
  padding: 64px 16px 0;

  &.bottomPadding {
    padding-bottom: 64px;
  }

  &:nth-child(odd) {
    background: $secondary-background;
  }

  aside > h1 {
    font-weight: normal;
    font-size: 30px;
    max-width: 80vw;
    text-align: center;
    letter-spacing: 0.26px;
    line-height: 43px;
    margin: 0;

    @media (min-width: $md-breakpoint) {
      padding-left: 16px;
      text-align: inherit;
    }
  }

  img {	
    width: 90vw;	
  }

  aside > .description {
    font-size: 14px;
    letter-spacing: 0.7px;
    width: 90vw;
    padding: 0 16px;
    max-width: 90vw;
    line-height: 23px;
    text-align: center;
    color: #222;
  }

  aside button {
    margin-left: 16px;
  }

  & > * {
    max-width: 90vw;
  }
}

@media (min-width: $md-breakpoint) {
  div.landingSection {
    padding: 200px 0 0 0;

    &.bottomPadding {
      padding-bottom: 100px;
    }

    aside > .description {
      text-align: inherit;
    }

    aside h1 {
      font-weight: normal;
      letter-spacing: 0.26px;
      font-size: 38px;
      line-height: 43px;
      margin: 0;
    }
  }
}
