.header {
  background: #f7f7f7;
  padding-bottom: 25px;
}

.pageHeading {
  max-width: 750px;
  margin-bottom: 45px;
}

.tagLine {
  margin-top: 150px;
}

.button {
  width: 270px;
}

.mission .ctaSection {
  padding: 0 32px;
  position: relative;
  height: 710px;
}
.mission .ctaSection h3 {
  color: #222222;
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 12px;
}
.mission .ctaSection h1 {
  font-size: 38px;
  line-height: 47px;
  font-weight: 200;
  letter-spacing: 1px;
  max-width: 760px;
  text-align: center;
}
.mission .ctaSection button {
  min-width: 270px;
}
.mission .missionSection {
  background-color: #e9f9f7;
  padding: 64px 32px;
}
.mission .missionSection .wrapper {
  max-width: 500px;
}
.mission .missionSection .wrapper h1 {
  letter-spacing: 0.25px;
  line-height: 41px;
  font-size: 35px;
  font-weight: 200;
}
.mission .missionSection .wrapper p {
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 25px;
}
.mission .leadershipSection {
  padding: 64px 32px;
  border-top: 1px solid #e6e6e6;
}
.mission .leadershipSection h1 {
  margin-bottom: 0;
  font-size: 36px;
  letter-spacing: 0.02px;
  line-height: 41px;
  color: #333333;
  font-weight: normal;
}
@media (min-width: 850px) {
  .mission .leadershipSection h1 {
    margin-bottom: 30px;
  }
}
.mission .leadershipSection .leadershipProfiles {
  flex-wrap: wrap;
}
.mission .supportSection {
  padding: 64px 32px;
  border-top: 1px solid #e6e6e6;
}
.mission .supportSection h1 {
  color: #333333;
  font-size: 36px;
  line-height: 41px;
  letter-spacing: 0.25px;
  font-weight: 200;
  margin: 0;
  text-align: center;
}
.mission .supportSection p {
  max-width: 560px;
  text-align: center;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.35px;
  color: #424242;
}
.mission .supportSection button {
  width: 164px;
}

@media (min-width: 850px) {
  .mission > * {
    padding-left: 0;
    padding-right: 0;
  }
  .mission .ctaSection h1 {
    font-size: 41px;
  }
  .mission .missionSection {
    padding-top: 128px;
    padding-bottom: 128px;
  }
  .mission .leadershipSection {
    padding: 146px 128px 260px 128px;
  }
  .mission .supportSection {
    padding: 150px 0;
  }
}