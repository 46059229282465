div.heroSignUp {
  min-height: 500px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  font-size: 13px;
  position: relative;
}
div.heroSignUp h1 {
  font-size: 42px;
  font-weight: normal;
  max-width: 260px;
  margin: 32px;
  text-align: center;
}
div.heroSignUp .tagLine {
  font-size: 15px;
  padding: 0px 64px;
  text-align: center;
}
div.heroSignUp button {
  margin-top: 15px;
}
div.heroSignUp .afterword {
  margin-top: 0;
  margin-bottom: 25px;
}
div.heroSignUp .donateButton {
  width: 270px;
  display: block;
  margin-top: 75px;
}
div.heroSignUp .donateReason {
  font-size: 12px;
  line-height: 17px;
  max-width: 260px;
  font-weight: 300;
  text-align: center;
  color: #a3a3a3;
}

@media (min-width: 850px) {
  div.heroSignUp {
    min-height: 800px;
  }
  div.heroSignUp h1 {
    font-size: 52px;
  }
  div.heroSignUp .afterword {
    position: absolute;
    bottom: 80px;
  }
  div.heroSignUp .tagLine {
    padding: 0;
  }
}