@import '../../../styles/vars.scss';

.milestone {
  margin-bottom: 30px;
  position: relative;

  &.nextDone {
    &:before {
      background-color: $main-green;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 45px;
    left: 16px;
    width: 2px;
    height: calc(100% - 26px);
    background-color: $light-gray;
  }

  .checkIcon {
    &.done {
      background-color: $main-green;
      border: 2px solid $main-green;
    }
    border: 2px solid $light-gray;
    width: 35px;
    height: 35px;
    border-radius: 100%;
  }

  aside {
    max-width: 220px;

    h3 {
      margin: 5px 0;
      font-size: 16px;
      line-height: 22px;
    }

    .description,
    .date {
      font-size: 12px;
      letter-spacing: 0.3px;
      line-height: 21px;
    }

    .description {
      font-weight: 400;
    }

    .date {
      font-weight: 300;
      color: $dark-gray;
    }
  }

  .endCap {
    width: 10px;
    height: 10px;
    margin: 0;
    position: absolute;
    bottom: -24px;
    left: 12px;
    background-color: $light-gray;
    border-radius: 100%;
  }
}
