@import 'vars';
@import 'layout';
@import 'forms';

body {
  color: $main-font;
  font-family: $font;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

h1,
h2 {
  font-family: $font-serif;
}

* {
  box-sizing: border-box;
}

a {
  color: $main-font;
  text-decoration: none;

  &:hover {
    color: inherit;
  }

  &:visited {
    color: inherit;
  }

  &:active {
    color: inherit;
  }
}

.svg-wrapper {
  position: relative;

  div {
    display: inline-block;

    svg {
      max-width: 100%;
      max-height: 100%;
      height: auto;
    }
  }
}

.todo {
  border: 2px solid #f1c40f;
  border-radius: 2px;
  color: #e67e22;
  width: 300px;
  padding: 16px;
  text-align: center;
  position: relative;

  &:before {
    content: '⚠️TODO: ';
    font-weight: 600;
    position: absolute;
    top: -26px;
    left: 0px;
  }
}
