.addMore {
  color: #666666;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 15px;
  font-weight: 300;
  text-align: right;
  cursor: pointer;
}
.addMore:hover {
  color: #000;
}