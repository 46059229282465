div.landingSection {
  position: relative;
  background-color: white;
  border-top: 1px solid #e6e6e6;
  padding: 64px 16px 0;
}
div.landingSection.bottomPadding {
  padding-bottom: 64px;
}
div.landingSection:nth-child(odd) {
  background: #f8f8f8;
}
div.landingSection aside > h1 {
  font-weight: normal;
  font-size: 30px;
  max-width: 80vw;
  text-align: center;
  letter-spacing: 0.26px;
  line-height: 43px;
  margin: 0;
}
@media (min-width: 850px) {
  div.landingSection aside > h1 {
    padding-left: 16px;
    text-align: inherit;
  }
}
div.landingSection img {
  width: 90vw;
}
div.landingSection aside > .description {
  font-size: 14px;
  letter-spacing: 0.7px;
  width: 90vw;
  padding: 0 16px;
  max-width: 90vw;
  line-height: 23px;
  text-align: center;
  color: #222;
}
div.landingSection aside button {
  margin-left: 16px;
}
div.landingSection > * {
  max-width: 90vw;
}

@media (min-width: 850px) {
  div.landingSection {
    padding: 200px 0 0 0;
  }
  div.landingSection.bottomPadding {
    padding-bottom: 100px;
  }
  div.landingSection aside > .description {
    text-align: inherit;
  }
  div.landingSection aside h1 {
    font-weight: normal;
    letter-spacing: 0.26px;
    font-size: 38px;
    line-height: 43px;
    margin: 0;
  }
}