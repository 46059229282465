@mixin layout($postfix, $breakpoint) {
  $layout: 'data-layout' + $postfix;
  $flex: 'data-flex' + $postfix;

  @media (min-width: $breakpoint) {
    [#{$flex}] {
      flex: 1 0 0%;
    }

    @include flex($flex, 2);
    @include flex($flex, 3);
    @include flex($flex, 4);
    @include flex($flex, 5);
    @include flex($flex, 6);

    [#{$layout}] {
      display: flex;
      align-items: stretch;
      min-width: 0;
      min-height: 0;

      > * {
        flex-shrink: 0;
      }
    }

    [#{$layout}~='inline'] {
      display: inline-flex;
    }

    [#{$layout}~='column'] {
      flex-direction: column;
    }

    [#{$layout}~='row'] {
      flex-direction: row;
    }

    [#{$layout}~='row-reverse'] {
      flex-direction: row-reverse;
    }

    [#{$layout}~='align-center'] {
      align-items: center;
    }

    [#{$layout}~='align-baseline'] {
      align-items: baseline;
    }

    [#{$layout}~='align-start'] {
      align-items: flex-start;
    }

    [#{$layout}~='align-end'] {
      align-items: flex-end;
    }

    [#{$layout}~='end'] {
      justify-content: flex-end;
    }

    [#{$layout}~='between'] {
      justify-content: space-between;
    }

    [#{$layout}~='around'] {
      justify-content: space-around;
    }

    [#{$layout}~='middle'] {
      justify-content: center;
    }

    [#{$layout}~='start'] {
      justify-content: flex-start;
    }

    [#{$layout}~='wrap'] {
      flex-wrap: wrap;
    }

    @include spacing($layout, u0, 0);
    @include spacing($layout, u05, $unit-half);
    @include spacing($layout, u1, $unit);
    @include spacing($layout, u2, $unit-x2);
    @include spacing($layout, u3, $unit-x3);
    @include spacing($layout, u4, $unit-x4);
    @include spacing($layout, u5, $unit-x5);
  }
}

@mixin flex($flex, $number) {
  [#{$flex}='#{$number}'] {
    flex: $number 0 0%;
  }
}

@mixin spacing($layout, $name, $size) {
  [#{$layout}~='#{$name}'] {
    &[#{$layout}~='row'] {
      > * + * {
        margin-top: 0;
        margin-left: $size;
      }
    }

    &[#{$layout}~='column'] {
      > * + * {
        margin-top: $size;
        margin-left: 0;
      }
    }
  }
}

@include layout('', 0px);
@include layout('-md', $md-breakpoint);
@include layout('-lg', $lg-breakpoint);
