@import '../../styles/vars.scss';

.stats {
}

.item {
  + .item {
    @media (min-width: $md-breakpoint) {
      margin-left: 94px;
    }
  }

  text-align: center;
  @media (min-width: $md-breakpoint) {
    text-align: left;
  }

  span {
    color: $font-dark;
    font-size: 48px;
    font-weight: 300;
    line-height: 65px;
  }

  p {
    width: 184px;
    color: $font-dark;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 20px;
    white-space: pre-line;
  }
}
