div.heroImage {
  background: #666;
  min-height: 35vh;
  position: relative;
}
div.heroImage .imageContent {
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
}
div.heroImage .description {
  font-size: 11px;
  color: white;
  text-align: right;
  padding-right: 64px;
  padding-bottom: 40px;
  display: none;
}
@media (min-width: 850px) {
  div.heroImage .description {
    display: block;
  }
}