@import '../styles/vars.scss';

.section {
  border-top: 1px solid $light-gray;
  padding: 100px 32px 75px 32px;

  @media (min-width: $md-breakpoint) {
    padding: 150px 0 125px 0;
  }
}

.sectionHeading {
  color: $font-dark;
  font-size: 36px;
  letter-spacing: 0.25px;
  line-height: 41px;
  text-align: center;
  font-weight: 400;
  margin: 0 0 30px;
}

.sectionDescription {
  max-width: 550px;
  color: $font-dark;
  font-size: 14px;
  letter-spacing: 0.7px;
  line-height: 23px;
  text-align: center;
  margin-top: 0;
}

.header {
  background: #f7f7f7;
  padding-bottom: 25px;
}

.button {
  margin-top: 30px;
  width: 270px;
  margin-bottom: 80px;
}

.tagLine {
  margin-top: 25px;
}

.stickyWrapper {
  height: 60px;
}

.sticky {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 16px;
  background: #fff;
  position: absolute;
  width: 100%;

  @media (min-width: $md-breakpoint) {
    padding: 10px 64px;
  }

  &Active {
    z-index: 5;
    position: fixed;
    top: 0;
    background: rgba(white, 0.8);
    backdrop-filter: blur(10px);
  }
}

.stickyLeft {
  color: $font-dark;
  font-size: 15px;
  display: none;

  @media (min-width: $md-breakpoint) {
    display: flex;
  }
}

.statsLine {
  padding: 75px 0;
  @media (min-width: $md-breakpoint) {
    padding: 125px 0;
  }
}

.faq {
  max-width: 750px;
  margin-top: 55px;
}

.issuesWrapper {
  margin-top: 75px;
}
