@import '../styles/vars.scss';

.contactUs {
  .hero {
    height: 500px;
    background-color: $secondary-background;
    background-repeat: no-repeat;
    background-size: 280px;
    background-position: calc(100% - 20px) bottom;

    .tagLine {
      font-size: 12px;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    h1 {
      font-size: 48px;
      letter-spacing: 1.17px;
      font-weight: 300;
    }
  }

  .contacts {
    padding: 60px 10%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    justify-content: space-around;
    row-gap: 60px;

    @media (min-width: $md-breakpoint) {
      row-gap: 160px;
      padding: 160px 10%;
    }

    .contactBlock {
      max-width: 230px;
      text-align: center;

      h1 {
        font-weight: 300;
        font-size: 28px;
      }

      .mail {
        font-size: 14px;
        letter-spacing: 0.7px;
        &:hover {
          color: $main-green;
        }
      }
    }
  }
}

@media (min-width: $md-breakpoint) {
  .contactUs {
    .hero {
      background-size: 400px;
      background-position: calc(50% + 280px) bottom;
    }

    .contacts {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
