.profileAvatar {
  background-color: #f8f8f8;
  width: 3.5em;
  height: 3.5em;
  border-radius: 3.5em;
  position: relative;
}
.profileAvatar.clickable {
  cursor: pointer;
}
.profileAvatar.small {
  font-size: 11px;
}
.profileAvatar.small .star {
  width: 12px;
  height: 12px;
}
.profileAvatar.small .star .starIcon {
  margin-top: -3px;
  margin-left: -1px;
}
.profileAvatar.small .star .starIcon div > svg {
  width: 6px;
  height: 6px;
}
.profileAvatar.medium {
  font-size: 16px;
}
.profileAvatar.medium .star {
  width: 16px;
  height: 16px;
}
.profileAvatar.medium .star .starIcon {
  margin-top: -2px;
}
.profileAvatar.medium .star .starIcon div > svg {
  width: 10px;
  height: 10px;
}
.profileAvatar.large {
  font-size: 28px;
}
.profileAvatar.large .star {
  width: 32px;
  height: 32px;
}
.profileAvatar.large .star .starIcon {
  margin-top: -2px;
}
.profileAvatar .star {
  background-color: #25c5ae;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 100%;
}
.profileAvatar .initials {
  font-weight: 300;
  color: #333333;
}