.dropdownMenu {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  border-radius: 1px;
  background-color: white;
  width: 240px;
  transform: translateY(100%);
}
.dropdownMenu .buttons {
  border-top: 1px solid rgba(230, 230, 230, 0.5);
}
.dropdownMenu .buttons > * + * {
  border-top: 1px solid #e6e6e6;
}