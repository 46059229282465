@import '../styles/vars';

.form {
  width: 270px;
  input {
    &:last-of-type {
      margin-bottom: 7px;
    }
  }
}

.terms {
  font-size: 12px;
  line-height: 17px;
  max-width: 260px;
  font-weight: 300;
  text-align: center;
  color: $gray;

  a {
    color: $gray;
    font-weight: 400;
    &:hover {
      color: #111;
    }
  }

  strong {
    white-space: nowrap;
  }
}

.signIn {
  font-size: 12px;
  padding-bottom: 25px;

  span {
    cursor: pointer;
    color: $main-green;
    font-weight: bold;
    margin-left: $unit-x2;
  }
}

.errorMessage {
  text-align: center;
  color: $error;
  font-size: 12px;
}
