.joinUsWidget {
  display: none;
}

@media (min-width: 850px) and (max-width: 1100px) {
  .joinUsWidget {
    display: block;
    width: 540px;
  }
}
@media (min-width: 850px) {
  .joinUsWidget {
    display: block;
    width: 418px;
  }
  .joinUsWidget h1 {
    margin-bottom: 38px;
    font-size: 21px;
    font-weight: 400;
    line-height: 24px;
    color: #333333;
  }
  .joinUsWidget .inputLine input {
    background: transparent;
    width: 270px;
  }
  .joinUsWidget .inputLine button {
    width: 140px;
  }
  .joinUsWidget .description {
    margin-top: 24px;
  }
  .joinUsWidget .description,
.joinUsWidget .error {
    max-width: 400px;
    color: #333333;
    font-size: 12px;
    letter-spacing: 0.6px;
    line-height: 21px;
  }
  .joinUsWidget .signUpSuccess {
    color: #666666;
    font-size: 12px;
    letter-spacing: 0.6px;
    line-height: 21px;
  }
  .joinUsWidget .error {
    color: #c4001d;
  }
}