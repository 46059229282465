@import '../styles/vars.scss';

.joinUsWidget {
  display: none;
}

@media (min-width: $md-breakpoint) and (max-width: $lg-breakpoint) {
  .joinUsWidget {
    display: block;
    width: 540px;
  }
}

@media (min-width: $md-breakpoint) {
  .joinUsWidget {
    display: block;
    width: 418px;

    h1 {
      margin-bottom: 38px;
      font-size: 21px;
      font-weight: 400;
      line-height: 24px;
      color: $font-middle;
    }

    .inputLine {
      input {
        background: transparent;
        width: 270px;
      }

      button {
        width: 140px;
      }
    }

    .description {
      margin-top: 24px;
    }

    .description,
    .error {
      max-width: 400px;
      color: $font-middle;
      font-size: 12px;
      letter-spacing: 0.6px;
      line-height: 21px;
    }

    .signUpSuccess {
      color: $dark-gray;
      font-size: 12px;
      letter-spacing: 0.6px;
      line-height: 21px;
    }

    .error {
      color: $error;
    }
  }
}
