@import '../../styles/vars';

.option {
  padding: 0.8em 1.4em;
  color: white;
  border-radius: 30px;
  cursor: pointer;
  outline: none;
  border: 1px solid $gray;
  color: $dark-gray;
  font-size: 14px;
  letter-spacing: 0.8px;
  line-height: 22px;
  font-weight: 300;
  width: 30%;
  text-align: center;
  margin-bottom: 10px;

  @media (min-width: $md-breakpoint) {
    padding: 1.1em 1.7em;
    font-size: 16px;
    width: auto;
  }

  &:disabled {
    opacity: 0.5;
  }

  &:hover {
    border-color: $main-green;
  }

  &Active,
  &:active {
    border-color: transparent;
    background: $main-gradient;
    color: #fff;
  }

  &Small {
    @media (min-width: $md-breakpoint) {
      padding: 0.5em 1em;
      margin: 0 0.2em;
      font-size: 14px;
      width: auto;
    }
  }
}

.customValue {
  background-color: #f7f7f7;
  margin-top: 25px;
  padding: 15px 25px;
  color: $dark-gray;
  font-size: 16px;
  letter-spacing: 0.8px;
  line-height: 22px;
  font-weight: 300;
}

.customValueInput {
  font-size: 13px;
  border-radius: 0;
  flex-grow: 1;

  @media (min-width: $md-breakpoint) {
    margin-right: 2em;
    flex-grow: 0;
  }
}

.currency {
  padding: 1em 1em;
  color: $main-font;
  border: 1px solid $light-gray;
  background: #fff;
  color: $darker-gray;
  font-size: 13px;
  letter-spacing: 0.65px;
  line-height: 18px;
  border-right: 0;

  @media (min-width: $md-breakpoint) {
    margin-left: 1em;
  }
}
