@import '../../styles/vars';

.donateForm {
  margin-top: 50px;
  max-width: 700px;
  border: 1px solid $light-gray;
  border-radius: 1px;
  background-color: #ffffff;

  @media (min-width: $md-breakpoint) {
    margin-top: 100px;
  }

  input,
  textarea {
    border-radius: 1px;
    font-size: 13px;
  }

  textarea {
    height: 100px;
  }
}

.formSection {
  padding: 25px 32px;
  + .formSection {
    border-top: 1px solid $light-gray;
  }

  @media (min-width: $md-breakpoint) {
    padding: 50px 35px;
  }
}

.paySection {
  &Content {
    text-align: center;
    max-width: 270px;
  }
}

.formSectionTitle {
  color: $font-dark;
  font-size: 18px;
  letter-spacing: 0.9px;
  font-family: $font;
  line-height: 24px;
  margin-top: 0;
  font-weight: 400;
}

.formSectionTitleSimple {
  margin-top: 0;
  color: $dark-gray;
  font-family: $font;
  font-size: 15px;
  letter-spacing: 0.75px;
  line-height: 20px;
  text-align: center;
  font-weight: 300;
}

.customInput {
  padding: 0.8em 1.45em;
  cursor: text;
  color: $main-font;
  border: 1px solid $light-gray;
  font-size: 13px;
  border-radius: 1px;

  &.invalid {
    border-color: $error;
  }

  &.active {
    border-color: $main-green;
  }
}

.recaptcha {
  padding-top: 30px;
}

.footerAmount {
  font-weight: 400;
  color: $font-dark;
  font-size: 18px;
  letter-spacing: 0.9px;
  line-height: 24px;
}

.footerTerms {
  color: $dark-gray;
  font-size: 12px;
  margin-top: 20px;
  letter-spacing: 0.6px;
  line-height: 17px;
}

.suffix {
  color: $dark-gray;
  font-size: 12px;
  letter-spacing: 0.28px;
  line-height: 17px;
  font-weight: 300;
}

.characterCount {
  color: $darker-gray;
  font-family: $font;
  font-size: 11px;
  letter-spacing: 0.55px;
  line-height: 15px;
  text-align: right;
  margin-top: 5px;
}
